<script setup>
import { ref, watch } from "vue";
import { Head, Link, useForm } from "@inertiajs/vue3";
import AuthenticationCard from "@/Components/AuthenticationCard.vue";
import AuthenticationCardLogo from "@/Components/AuthenticationCardLogo.vue";
import Checkbox from "@/Components/Checkbox.vue";
import InputError from "@/Components/InputError.vue";
import InputLabel from "@/Components/InputLabel.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import TextInput from "@/Components/TextInput.vue";

defineProps({
    canResetPassword: Boolean,
    canRegister: Boolean,
    status: String,
    error: String,
    errors: Object,
    webRouteRegistration: String,
});

const form = useForm({
    phone: "",
    password: "",
    remember: false,
});

const submit = () => {
    form.transform((data) => ({
        ...data,
        remember: form.remember ? "on" : "",
    })).post(route("login"), {
        onFinish: () => form.reset("password"),
        onError: () => form.reset("password"),
    });
};

const toggle = ref(true);
const togglePassword = () => {
    toggle.value = !toggle.value;
    return null;
};

const register = (goTo) => {
    // window.location.href = "https://line.me/R/ti/p/@536jfrur"
    // window.location.href = route("register");
    window.location.href = goTo
}
</script>

<template>
    <Head title="ยินดีต้อนรับสู่ระบบ" />

    <AuthenticationCard>
        <template #logo>
            <AuthenticationCardLogo />
        </template>

        <template #head>
            <div>
                <p class="text-lg font-bold">ยินดีต้อนรับสู่ระบบ</p>
                <p class="text-base">โปรดลงชื่อเข้าใช้บัญชีของคุณ</p>
            </div>
        </template>

        <div
            v-if="status"
            class="mb-4 text-center font-bold text-base text-green-400"
        >
            {{ status }}
        </div>

        <InputError
            class="mt-2 text-center"
            :message="errors.message ? errors.message[0] : ''"
        />

        <form @submit.prevent="submit">
            <div>
                <InputLabel
                    class="text-white"
                    for="phone"
                    value="เบอร์โทรศัพท์"
                />
                <TextInput
                    id="phone"
                    v-model="form.phone"
                    type="text"
                    class="mt-1 block w-full"
                    autofocus
                    autocomplete="phone"
                    maxlength="10"
                    @keypress="onlyNumberInput($event)"
                />
                <InputError class="mt-2" :message="form.errors.phone" />
                <InputError class="mt-2" :message="form.errors.email" />
            </div>

            <div class="mt-4">
                <InputLabel
                    class="text-white"
                    for="password"
                    value="รหัสผ่าน"
                />

                <div class="w-full relative">
                    <div
                        class="absolute inset-y-0 right-0 flex items-center px-2"
                    >
                        <input
                            class="hidden js-password-toggle"
                            id="toggle"
                            type="checkbox"
                        />
                        <label
                            for="toggle"
                            class="bg-transparent cursor-pointer"
                            @click="togglePassword"
                        >
                            <svg
                                v-if="toggle"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="1.5"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                class="h-6"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                ></path>
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                ></path>
                            </svg>
                            <svg
                                v-else
                                fill="none"
                                stroke="currentColor"
                                stroke-width="1.5"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                class="h-6"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                ></path>
                            </svg>
                        </label>
                    </div>
                    <TextInput
                        id="password"
                        v-model="form.password"
                        :type="toggle ? 'password' : 'text'"
                        class="mt-1 block w-full"
                        autocomplete="current-password"
                    />
                </div>

                <div
                    v-if="errors?.credential"
                    class="my-2 text-center text-base text-electric-red-600"
                >
                    {{ errors.credential }}
                </div>

                <InputError class="mt-2" :message="form.errors.password" />
            </div>

            <div class="flex items-center justify-end mt-4 gap-2">
                <Link
                    v-if="canRegister"
                    href="#"
                    @click="register(webRouteRegistration)"
                    class="underline text-sm text-blue-500 hover:text-white rounded-md focus:outline-none focus:ring-0"
                >
                    ยังไม่มีบัญชี?
                </Link>

                <Link
                    v-if="canResetPassword"
                    :href="route('password.request')"
                    class="underline text-sm text-blue-500 hover:text-white rounded-md focus:outline-none focus:ring-0"
                >
                    ลืมรหัสผ่าน?
                </Link>
            </div>

            <div class="mt-5">
                <PrimaryButton
                    class="mt-1 block w-full"
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                >
                    <span class="text-center w-full font-normal"
                        >เข้าสู่ระบบ</span
                    >
                </PrimaryButton>
            </div>
        </form>
    </AuthenticationCard>
</template>
